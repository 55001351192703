<template>
    <Form :data="data" @submit="submit">
        <template #success>
            Your password has been<br> successfully reset
        </template>
    </Form>
</template>


<script>
import Form from '@/components/auth/Form'
import { reactive } from '@vue/reactivity'
import api from '@/services/auth/auth.service.js'
import { getValidationError, clearValidationError } from '@/helpers/objects'
import { useRoute, useRouter } from 'vue-router'

export default {
    props: [''],
    components: {
        Form
    },
    setup() {        
        const route = useRoute()
        const router = useRouter()

        const { token, email } = route.query
        if(!token || !email) router.push('/')

        const data = reactive({
            step: 'form',
            successStepClass: 'bold',
            title: 'Set new password',
            inputs: [
                { label: 'New Password', placeholder: 'Enter new password', value: '', type: 'password', key: 'password', error: '' },
                { label: 'Confirm Password', placeholder: 'Enter password confirmation', value: '', type: 'password', key: 'password_confirmation', error: '' },
            ],
            btn: {
                title: 'RESET PASSWORD',
                type: 'primary',
                icon: 'fas fa-sign-in-alt',
                loading: false,
            }
        })

        async function submit(payload) {
            clearValidationError(data.inputs)
            payload.token = token
            payload.email = email

            const result = await api.setPassword(payload)
            data.btn.loading = false

            if(result.errors || result.success == false) {
                if(result.errors) getValidationError(result.errors, data.inputs)
                return
            }
            showSuccesssStep()
        }



        function showSuccesssStep() {
            data.step = 'success'
            
            const btnSuccesStep = {
                title: 'BACK to login',
                href: '/login',
                type: 'primary',
                icon: 'fas fa-sign-in-alt',
            }
            
            data.btn = btnSuccesStep
            delete data.title
        }

        return {
            data,
            submit
        }
    }
}
</script>


<style scoped lang="scss">
::v-deep .auth-form__btn {
    margin-top: auto;
}
::v-deep .auth-form__success {
    margin-top: 89px;
    margin-bottom: -12px;
}
</style>